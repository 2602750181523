<template>
  <!--Navbar Start-->
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
    id="navbar"
    :class="{'navbar-light': navbarcolor === true}"
  >
  
    <div class="container">
      <!-- LOGO -->
      <div class="navbar-brand logo">
        <h1 style="color: #000;"><img src="@/assets/images/logo.png" alt="LeapFrogr" style="max-width:250px; width: auto; position: relative;"></h1>
      </div>

      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu()"
        aria-label="Toggle navigation"
      >
        <i class="mdi mdi-menu"></i>
      </button>
 

<div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mx-auto navbar-center">
              <b-nav-item-dropdown text="Phone" right>
                <b-dropdown-item v-b-modal.phone-update-modal>{{number}}<small> (edit)</small></b-dropdown-item>
                <b-dropdown-form @click="updateCallbacksoptin()"><b-form-checkbox v-model="callbacks" switch name="check-button" v-b-popover.hover.left="'If callbacks are on you will receive a callback if it is available instead of waiting on hold.'">Callbacks </b-form-checkbox></b-dropdown-form>
              </b-nav-item-dropdown>
              <b-nav-item-dropdown text="Funds" right>
                <b-dropdown-item v-b-modal.modal-funds @click="onLoadPaymentForm">Balance: {{balance | currency}}</b-dropdown-item>
                <b-dropdown-item v-b-modal.modal-funds @click="onLoadPaymentForm">Add Funds</b-dropdown-item>
                <a :href="lastreceipt" target="_blank">Last Receipt</a>
                
              </b-nav-item-dropdown>
          <li class="nav-item">
            <b-dropdown-item v-b-toggle.sidebar>Support</b-dropdown-item>
          </li>
          <li class="nav-item">
            <a href="javascript: void(0);" class="nav-link" @click="signout"><a href="javascript: void(0);" v-b-modal.login-modal>Logout</a></a>
          </li>
        </ul>
      </div>

    </div>
    
  </nav>
  

  <!-- Navbar End -->
</template>



<script>
import firebase from 'firebase'
import axios from 'axios';


export default {
  data: function() {
    return {
      callbacks: true,
      //callbacks: true,
      id: null,
      paymentBuilt: false,
      errors: [],
      paymentSubmit: true,
      masterpass: false,
      applePay: false,
      selected: null,

    };
  },
  props: {
    navbarcolor: {
      type: Boolean,
      default: false
    },
    uid:String,
    number:String,
    balance:Number,
    lastreceipt:String,
    callbacksoptinNav:Boolean,
  },
  watch:{
    'callbacksoptinNav'(newVal){
        this.callbacks = newVal
    }
  }, 
  mounted: function()  {
    window.onscroll = function() {
      onwindowScroll();
    };
    var navbar = document.getElementById("navbar");
    function onwindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    }
    

  },
  methods: {
      signout()
      {
          firebase.auth().signOut()
          .then((resp)=>{
              window.location.href = 'https://leapfrogr.com';
          })
          .catch((error)=>{
              console.log(error)
          })
      },
      onLoadPaymentForm () {
        this.$emit('clickedPaymentForm', true)
      },
      toggleMenu() {
        document.getElementById("navbarCollapse").classList.toggle("show");
      },
      updateCallbacksoptin()
      {
        console.log(this.callbacksoptinNav)
        axios.get('https://api.leapfrogr.com/updatecallbacks', { params: {uid:  this.uid, callbacks: !this.callbacks} })
        
      },

  }
};
</script>

<style>
.btn.btn-outline-info {
  padding: 5px
}

.sq-input {
  border: 1px solid rgb(223, 223, 223);
  outline-offset: -2px;
  margin-bottom: 5px;
  display: inline-block;
  border: none;
  color: #32325d;
  line-height: 18px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  height: 18px;
  -webkit-font-smoothing: antialiased;
}

.sq-input ::placeholder {
  color: #aab7c4;
  opacity: 0.5;
}

/* Define how SqPaymentForm iframes should look when they have focus */

/* Define how SqPaymentForm iframes should look when they contain invalid values */

.sq-input--error {
  outline: 3px auto rgb(255, 97, 97);
}

.errorbox {
  line-height: 14px;
  text-align: left;
}

.error {
  font-size: 10px;
  color: rgb(164, 0, 30);
  width: 45%;
  display: inline-block;

  margin-top: -10px;
  font-weight: 400;
}

/* Customize the "Pay with Credit Card" button */

.button-credit-card {
  min-width: 200px;
  min-height: 20px;
  padding: 0;
  margin: 5px;
  line-height: 20px;
  box-shadow: 2px 2px 1px rgb(200, 200, 200);
  background: rgb(255, 255, 255);
  border-radius: 5px;
  border: 1px solid rgb(200, 200, 200);
  font-weight: bold;
  cursor: pointer;
}

.card-number {
  width: 100%;
}

.modal .payButton {
  margin-left: 0px;
  position: absolute;
  bottom: 0px;
  width: 400px;
}

/* Customize the "{{Wallet}} not enabled" message */

.wallet-not-enabled {
  min-width: 200px;
  min-height: 40px;
  max-height: 64px;
  padding: 0;
  margin: 10px;
  line-height: 40px;
  background: #eee;
  border-radius: 5px;
  font-weight: lighter;
  font-style: italic;
  font-family: inherit;
  display: block;
}

/* Customize the Apple Pay on the Web button */

.button-apple-pay {
  min-width: 200px;
  min-height: 40px;
  max-height: 64px;
  padding: 0;
  margin: 10px;
  background-image: -webkit-named-image(apple-pay-logo-white);
  background-color: black;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  cursor: pointer;
  display: none;
}

/* Customize the Masterpass button */

.button-masterpass {
  min-width: 200px;
  min-height: 40px;
  max-height: 40px;
  padding: 0;
  margin: 10px;
  background-image: url(https://static.masterpass.com/dyn/img/btn/global/mp_chk_btn_147x034px.svg);
  background-color: black;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  border-color: rgb(255, 255, 255);
  cursor: pointer;
}

#sq-walletbox {
  text-align: center;
  vertical-align: top;
  font-weight: bold;
}

#sq-ccbox {
  margin: 5px;
  padding: 0px 10px;
  text-align: center;
  vertical-align: top;
  font-weight: bold;
}

.expiration-date,
.cvv,
.postal-code {
  width: 30%;
  display: inline-block;
}

#card-tainer {
  text-align: left;
  margin-top: 8px;
  background-color: white;
  height: 80px;
  padding: 10px 12px;
  border-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  box-sizing: border-box;
}
</style>  
