<template>
  <div>
    <Navbar :uid=uid :number=number :balance=balance :lastreceipt=lastreceipt :callbacksoptinNav=callbacksoptinNav @clickedPaymentForm="showPaymentForm"></Navbar> 
    
    <div v-scroll-spy>
      <!-- Hero Start -->
      <section
        class="hero-1-bg"
        :style="{'background-image': 'url(' + require('@/assets/images/hero-1-bg-img.png') + ')'}"
        id="home"
      >

<div class="container">

  <div v-if="fbdata.callStatus !== 'Done'">
    <b-card class="mb-8">
      <b-card-text><b>Call Status:</b> {{fbdata.callStatus}} 
        <status-indicator v-if="fbdata.callStatus  === 'Calling'" status="positive" pulse/>
        <br>
        <b-link @click="changeLogState">{{this.logsText}}</b-link>
      <div v-if="logView">
        <b-list-group>
        <div v-for='log in userLogs'>
          

            <b-list-group-item>{{log}}</b-list-group-item>  
          
          
        </div>
      </b-list-group>
        
      </div>  

      </b-card-text>
    </b-card>
  </div>

  <div class="accordion" role="tablist">
    <b-card v-for='callType in callTypes' no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle="'accordion-' + callType.lineType" variant="outline-secondary">{{callType.title}} <b v-if="$data[callType.lineType].length > 0"">   ({{$data[callType.lineType].length}} Live Calls)</b></b-button>
      </b-card-header>
      <b-collapse :id="'accordion-' + callType.lineType" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <table :items="callType" :fields="fieldsDedicated" class="table table-bordered">
            <thead>
                <tr>
                  <th scope="col">Start Call</th>
                  <th scope="col">Estimated Wait Time While on Hold</th>
                  <th scope="col">Cost</th>
                </tr>
              </thead>
                <tbody>
                  <tr>
                      <td v-if="uid === null">
                        <b-button size="sm" class="mr-2" variant="outline-danger"  title="Sign in">
                          Sign-in
                        </b-button>
                      </td>
                      <div v-else-if="fbdata.callStatus  === 'Done'">
                        <b-button size="sm" v-b-modal.modal-funds @click="showPaymentForm" class="mr-2" variant="outline-danger" v-b-popover.hover.left="'You need to add funds to buy this call.'" title="Add Funds">
                          Add Funds
                        </b-button>
                      </div>
                      <td v-else-if="fbdata.callStatus  !== 'Ready to Call'">
                        <b-button size="sm" class="mr-2" variant="outline-warning" v-b-popover.hover.right="'You currently have an active call. Please wait for this call to complete before starting another call. If this message is in error, please reach out to our support team.'" title="Call in Progress">
                          Call in Progress
                        </b-button>
                      </td>
                      <td v-else-if="time > callType.close || time > callType.open">
                        <b-button :disabled=true size="sm" class="mr-2" variant="outline-primary" v-b-popover.hover.right="'Check the calling hours for this call. Our hours differ slightly from the IRS.'" title="Starting a Call">
                          Not in Hours
                        </b-button>
                      </td>
                      <td v-else-if="callType.basecost > balance">
                        <b-button size="sm" v-b-modal.modal-funds @click="showPaymentForm" class="mr-2" variant="outline-danger" v-b-popover.hover.left="'You need to add funds to buy this call.'" title="Add Funds">
                          Add Funds
                        </b-button>
                      </td>  
                      <td v-else>
                        <b-button @click="startCall(callType.lineType)" size="sm" class="mr-2" variant="outline-primary" v-b-popover.hover.right="'When you purchase this call we will make multiple simultaneous calls until we get through to a hold line. If callbacks are available, and you opt-in you will receive a callback instead of a hold line.'" title="Starting a Call">
                          Start Call
                        </b-button>
                      </td>

                    
                      <td>
                        {{callType.waittime}} minutes
                      </td>
                      <td>
                        {{callType.basecost | currency}}
                      </td>
                  </tr>
                </tbody>
          </table>
          
          <b-table v-if="$data[callType.lineType].length > 0" responsive striped hover :items="$data[callType.lineType]" :fields="fields">
                <template #cell(buy_call)="row">
                  <div v-if="fbdata.callStatus  === 'Done'">
                    <b-button size="sm" v-b-modal.modal-funds @click="showPaymentForm" class="mr-2" variant="outline-danger" v-b-popover.hover.left="'You need to add funds to buy this call.'" title="Add Funds">
                       Add Funds
                    </b-button>
                  </div>
                  <div v-else-if="fbdata.callStatus  !== 'Ready to Call'">
                    <b-button size="sm" class="mr-2" variant="outline-warning" v-b-popover.hover.right="'You currently have a call that is not in Ready Status. Please wait for this call to complete before starting another call. If this message is in error, please reach out to our support team.'" title="Call in Progress">
                      Call in Progress
                    </b-button>
                  </div>
                  <div v-else-if="time > callType.close || time > callType.open">
                    <b-button :disabled=true size="sm" class="mr-2" variant="outline-primary" v-b-popover.hover.right="'By buying this call you will receive a call immediately and wait on hold for the remaining estimated wait time.'" title="Joining a Call">
                       Not in Hours
                    </b-button>
                  </div>
                  <div v-else-if="row.item.price > balance">
                    <b-button size="sm" v-b-modal.modal-funds @click="showPaymentForm" class="mr-2" variant="outline-danger" v-b-popover.hover.left="'You need to add funds to buy this call.'" title="Add Funds">
                       Add Funds
                    </b-button>
                  </div>
                  <div v-else>
                    <b-button size="sm" @click="joinCall(row.item.id, row.item.price)" class="mr-2" variant="outline-primary" v-b-popover.hover.right="'By buying this call you will receive a call immediately and wait on hold for the remaining estimated wait time.'" title="Joining a Call">
                       Join Call
                    </b-button>
                  </div>

                </template>
                <template #cell(price)="row">
                  {{ row.item.price | currency }}
                </template>

                <template #cell(progress)="row">
                        <b-progress :max=100 striped :animated=true>
                          <b-progress-bar :value="row.item.progress | percent(0) " :label="row.item.progress | percent(0) "></b-progress-bar>
                        </b-progress>
                </template >

          </b-table>


          <b-alert v-if="callType.lineWarning !== ''" show variant="danger">{{callType.lineWarning}}</b-alert>
          <small>Our calling line hours are from: {{callType.open}} to {{callType.close}} PST</small>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>

  <b-alert show>The IRS recently added audio captcha to the Practitioner Priority Lines. LeapFrogr now circumvents those measures and will still get a hold line.</b-alert>


  

      </div>

      <div>
  
  
    <b-sidebar id="sidebar" title="Support and FAQ" shadow>
    <div class="px-3 py-2">
      <div class="input-group mb-3">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M11.5 7a4.499 4.499 0 11-8.998 0A4.499 4.499 0 0111.5 7zm-.82 4.74a6 6 0 111.06-1.06l3.04 3.04a.75.75 0 11-1.06 1.06l-3.04-3.04z"></path></svg></span>
  </div>
  <input type="text" class="form-control" v-model="searchQuery" placeholder="Search..." aria-label="Search" aria-describedby="basic-addon1">
</div>
    </div>
      <div class="px-3 py-2" v-for="post of resultQuery">
        <h4>{{post.title}}</h4>
        <p><div v-html="post.body"></div></p>
      </div>
    </b-sidebar>
  </div>
  </section>

    <Footer />
   </div>




<b-modal id="modal-funds" title="Add Funds" hide-footer @hidden="hide" @show="show">

      <div id="sq-ccbox">
        <form id="payment-form">
              <div id="card-container"></div>
        </form>
        <div id="payment-status-container"></div>
          
          <input type="hidden" name="amount" v-model="cost">
          <div role="group">
            <b-form-input
              id="input-live"
              v-model="cost"
              :state="nameState"
              type="number"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="Funds must be between $5 and $200"
              trim
              style="text-align: center; font-size: 1rem; "
            ></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
              Must be a valid number between 5-200 without change
            </b-form-invalid-feedback>
            <b-form-text id="input-live-help">Funds in full dollar amount.</b-form-text>
          </div>

      </div>
      
      <br>
      <div class="row justify-content-center">
            <h1 style="padding-right: 10px;"><img src="@/assets/images/squareup_logo.png" alt="Squareup" style="max-width:200px; width: auto; position: relative;"></h1>
            <button v-show='paymentSubmit' @click="requestCardNonce($event)" class="btn btn-primary pr-btn">Pay ${{cost}}</button>
      </div>

</b-modal>


      <b-modal id="login-modal" ref=modal hide-header hide-footer centered no-close-on-backdrop>
            <div class="text-center">
                  <div class="navbar-brand logo">
                    <h1 style="color: #000;"><img src="@/assets/images/logo.png" alt="LeapFrogr" style="max-width:200px; width: auto; position: relative;"></h1>
                  </div>
              <h5 class="text-uppercase text-primary">
                <b>Sign-in or Register With Your Mobile Phone Number</b>
              </h5>
            </div>
            <small>We verify with this number but after sign-up you can add an additional landline to recieve callbacks.</small>
            <div class="login-form mt-4">
              <form @submit.prevent="submit" v-if="!smsSent">
                <div class="form-group">
                      <vue-tel-input v-model="phoneNumber" v-bind="bindProps"></vue-tel-input>
                </div>

                <div id="recaptcha-container" style="width:300px;margin:auto;"></div>

                <div class="text-center mt-4">
                
                <button class="btn btn-primary" id="log-in" type="submit">
                    Next
                    <arrow-right-circle-icon class="icon-size-15 icon ml-1"></arrow-right-circle-icon>
                  </button>
                </div>
              </form>

              <div v-if="smsSent" style="width:300px;height:180px;margin:auto; border-radius:10px;" class="text-center mt-4">
                  <br>
                  <div>
                      <input type="text" 
                      id="otpField"
                      style="text-align:center;"
                      pattern="[0-9]{6}"
                      maxlength="6"
                      v-model="otpnum"
                      placeholder="Enter Code"
                      required>
                  </div>
                  <br>
                  <div>
                      <button class="btn btn-primary" @click="verifyCode" id="otp-btn">Confirm</button>
                  </div>
              </div>
            </div>
      </b-modal>




      <b-modal id="phone-update-modal" hide-header hide-footer centered>
            <div class="text-center">
              <h5 class="text-uppercase text-primary">
                <b>Change Phone Number</b>
              </h5>
            </div>
            <small>Update your phone number where you would like to receive calls. <b>Current Active Number: {{number}}</b></small>
            <div class="login-form mt-4">
              <form>
                <div class="form-group">
                      <vue-tel-input v-model="primaryPhoneNumber" v-bind="bindPropsUpdate"></vue-tel-input>
                </div>
              </form>
                              
                <div class="text-center mt-4">
                  <button class="btn btn-primary" ref=phonemodal id="phoneupdatebutton" @click="updatePhone">Update <b-spinner v-if="updateclicked" small></b-spinner> <svg v-if="updatedone" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                    </svg>
                  </button>
                </div>
            </div>
      </b-modal>
  </div>
</template>


<script>

  import firebase from "firebase";
  import Navbar from "@/components/dashnav";
  import Footer from "@/components/footer";
  import axios from 'axios';


export default {
  name: "paymentForm",
  data: function() {
    return {
      searchQuery: null,
      postList: [
        {title: 'Getting Started', body: 'We require that people load a balance onto their account. After you add funds, you can start a call immediately or you can update your phone number if you do not wish to use your cellphone.'},
        {title: 'How Do I Get a Callback?', body: 'We do not guarantee callbacks but the IRS will sometimes allow us to enter your phone number to call you back directly. You have the option to opt-out from the phone menu, which is turned on by default. Sometimes this is not available based on the call type, time of day, or call volume. If callbacks are not available we will connect your line to wait on hold for the duration of the estimated wait time.'}, 
        {title: 'What are Dedicated Calls vs. Joining?', body: 'The two basic types of calls are ones you can join and dedicated calls that you can start from scratch. There are benefits to both. Dedicated calls have the possibility of receiving callbacks, while joining an already established line will have less wait time based on how far along the call is in the queue. If you do not see a progress bar under the call line we are only offering dedicated calls until volume picks up.'}, 
        {title: 'What Determines Pricing?', body: 'The primary cost is directly associated with the resources it takes to get through to a hold line using VoIP calling systems and STT software. It can take hours of calls to get through to a line during peak hours and we are always striving to reduce costs, which will be passed off to our customers.'},
        {title: 'What if My Call is Dropped?', body: 'Reach out to us and we will refund the price of the call to your account. We always guarantee that you will speak with an IRS representative when you place a call on LeapFrogr. If you receive a callback from the IRS we are no longer a part of that process and do not provide refunds once we verify that your phone number was accepted by the IRS call system.'},
        {title: 'How Do I Get My Funds Back?', body: 'When closing your account we will return the remaining balance to you. To close your account, reach out by using any of the contact methods below. Funds will be available in 5-7 days after your request is received.'},
        {title: 'What are Estimated Wait Times?', body: 'Estimated wait times refers to the amount of time that it will take to receive a call, or wait on hold. If the call is dedicated, then that is the estimated wait time <b>after</b> we get through to a hold line. We determine the wait time from the estimates given by the IRS, and do not guarantee them to be accurate.'},
        {title: 'Are My Calls Recorded?', body: 'Absolutely not. We use TTS (text to speech) technology for about 2-3 minutes of each phone call to grab wait times and callback availability, but it is discontinued before we join calls. '},
        {title: 'Are My Calls Secure?', body: 'Our software engineer comes from a strong I.T. security background. We only use enterprise databases, authentication and VoIP services. If you still have concerns about our services you should reach out to us to discuss by phone or video call.'},
        {title: 'Contacting Us', body: 'The fastest way to contact us is by chat at the bottom left of your screen. We can also be reached by email at support@leapfrogr.com, or by text at (559) 492-0196.'},
        {title: '', body: '<br><br><br><br><br>'},
      ],
      logView: false,
      logsText: 'Show Call Logs',
      interval: null,
      time: null,
      callbacksoptinNav: true,
      fields: [{key: 'id', thClass: 'd-none', tdClass: 'd-none' }, {key: 'buy_call'}, {key: 'price', sortable: true}, {key: 'start_time', sortable: true}, {key: 'est_time_left', sortable: true}, {key: 'progress'} ],
      fieldsDedicated: [{key: 'basecost'}, {key: 'cost'}, {key: 'est_wait_time', sortable: true},  ],
      id: null,
      updateclicked: false,
      updatedone: false,
      ppm: 0.20,
      balance: 0.00,
      lastreceipt: "",
      userLogs: [],
      callLines: [],
      GeneralBusiness: [],
      GeneralLine: [],
      PPBusiness: [],
      PPIndividual: [],
      PPCollections: [],
      BalanceDue: [],
      Appointments: [],
      TaxPayerProtectionHotline: [],
      callTypes: [],
      text: null,
      paymentSubmit: true,
      selected: null,
      isActiveOT: false,
      isActiveLT: false,
      number: null,
      primaryPhoneNumber: null,
      uid: null,
      user: null,
      account: null,
      cost: 10,
      fbdata: {
          callStatus: "Done",
      },
      errors: [],
      masterpass: false,
      applePay: false,
      phoneNumber:null,
      bindProps: {
        mode: "international",
        defaultCountry: "US",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "Enter your phone number",
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: ["US"],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabled: false,
          showFlags: true,
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      },
      bindPropsUpdate: {
        mode: "international",
        defaultCountry: "US",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "Enter your phone number",
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: ["US"],
        ignoredCountries: [],
        autocomplete: "on",
        name: "telephone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabled: false,
          showFlags: true,
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      },
      confirmationResult:null,
      number:null,
      otpnum:null,
      recaptchaVerifier:null,
      recaptchaWidgetId:null,
      confirmResult:null,
      smsSent:false,
      caitems: [],
    };
  },
  computed: {
    nameState() {
      this.cost == Math.floor(this.cost)
      return this.cost > 4 && this.cost < 200 & this.cost == Math.floor(this.cost) ? true : false
    },

    resultQuery() {
      if (this.searchQuery) {
        return this.postList.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.title.toLowerCase().includes(v));
        });
      } else {
        return this.postList;
      }
    }
  },
  onComplete() {


    if (this.uid == null) {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('log-in',{
            'size':'invisible',
            'callback':(response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            console.log(response)
            }
      })  
    }

    

  },
  methods: {
    changeLogState(){
      this.logView = !this.logView;
      this.logsText = this.logView?'Hide Call Logs':'Show Call Logs';
      console.log(this.logsText)
    },
    hide() {
      //this.$emit('hide')
      console.log("hide the modal")
      this.card.destroy()
    },
    async show() {
      console.log("show the modal")
      let locationId = "LJDHA04K9C551";
      let appId = "sq0idp-6GpC6dWQErRNuSpVAI1dhw";
      let that = this;

      const payments = Square.payments(appId, locationId)
      console.log(payments)
      this.card = await payments.card();
      console.log(this.card)
      await this.card.attach('#card-container');

    },
    showPaymentForm() {
      //https://developer.squareup.com/forums/t/card-payment-with-vue-js-tokenize-fail/4521/2
      //if (this.card == null) {

      //}
    },
    loadUser() 
    {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
              this.$refs["modal"].hide();
              this.number = user.phoneNumber
              this.uid = user.uid
              this.account = user
              console.log(user)
              firebase.firestore().collection("accounts").doc(user.uid).onSnapshot(querySnapshot => {
                if (querySnapshot.exists) {  
                  console.log(querySnapshot.data())
                  this.fbdata = querySnapshot.data()
                  this.balance = this.fbdata.balance * .01
                  this.userLogs = this.fbdata.logs
                  this.lastreceipt = this.fbdata.receipts[this.fbdata.receipts.length -1]
                  console.log(this.lastreceipt)
                  } else {
                    console.log("cant get user data")
                    this.balance = 0.00
                  }
                  if (this.fbdata.phone != null) {
                    this.number = this.fbdata.phone
                  }
                  if (this.fbdata.callbacksoptin != null) {
                      console.log("setting callbacksoptin: ", this.fbdata.callbacksoptin)
                      this.callbacksoptinNav = this.fbdata.callbacksoptin
                  }
              });
        } else {
              console.log('No user')
              this.user = null
              this.$refs["modal"].show();
        }
      });



    },
    updatePhone()
    {
      console.log(this.primaryPhoneNumber)


      if (this.primaryPhoneNumber.startsWith("+1") && this.primaryPhoneNumber.length == 15) {
        axios.get('https://api.leapfrogr.com/updatephone', { params: {uid:  this.uid, phone: this.primaryPhoneNumber} }).then(response => {
            console.log(response.data)
            this.updateclicked = false;
            this.updatedone = true;
        })
      }
      
    },
    submit()
    {
        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
        this.recaptchaVerifier.render().then((widgetId)=>{
        this.recaptchaWidgetId = widgetId    
        })
        
        var number = this.phoneNumber            
        firebase.auth().signInWithPhoneNumber(number,this.recaptchaVerifier)
        .then((confirmationResult)=>{                
            this.confirmResult = confirmationResult
            console.log(this.confirmResult)
            this.smsSent=true
        })
        .catch((error)=>{
            console.log("Sms not sent",error.message)
        })
    },
    verifyCode()
    {            
        this.confirmResult.confirm(this.otpnum)
        .then((result)=>{
            var user = result.user
            loadUser();

            console.log(user)                
        })
        .catch((error)=>{
            console.log(error)
        })
    },
    //requestCardNonce: function(event) {
    //  this.cost = Math.floor(this.cost)
      
      // Don't submit the form until SqPaymentForm returns with a nonce
    //  event.preventDefault();

      // Request a nonce from the SqPaymentForm object
    //  this.paymentForm.requestCardNonce();
    //  this.paymentSubmit = false;  
    //  setTimeout(() => this.paymentSubmit = true, 8000)
    //},
    async requestCardNonce(event) {
      this.cost = Math.floor(this.cost)
      event.preventDefault();


      try {

        const result = await this.card.tokenize();

        if (result.status === 'OK') {

          console.log(`Payment token is ${result.token}`);
          console.log(this.cost);
          console.log(this.uid);

          //axios.get('https://api.leapfrogr.com/', { params: {nonce: `${result.token}`, uid: this.uid, amount: this.cost} }).then(response => {
          axios.get('https://api.leapfrogr.com/', { params: {nonce: `${result.token}`, uid: this.uid, amount: this.cost} }).then(response => {
            console.log(response.data)
            if (response.data == "COMPLETED") {
              this.$swal({
                position: 'top-end',
                icon: 'success',
                title: 'Payment Successful!',
                showConfirmButton: false,
                timer: 1500
              })
            } else {
                  this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Payment Unsuccessful. Please check your infmormation.',
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
          })

        }

      } catch (e) {

        console.error(e);

      }




      this.paymentSubmit = false;  
      setTimeout(() => this.paymentSubmit = true, 8000)
    },                                                                                  
    onComplete() {
    },
    joinCall(callid, price) {
      console.log(callid, price)
      axios.get('https://api.leapfrogr.com/joincall', { params: {uid:  this.uid, phone: this.number, irsid: callid, cost: price} }).then(response => {
        console.log(response.data)
      })
    },
    startCall(callbackname) {
      axios.get('https://api.leapfrogr.com/startcall', { params: {uid:  this.uid, phone: this.number, calltype: callbackname} }).then(response => {
        console.log(response.data)
      })
    },


  },
  created()
  {

    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat('en-GB', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: "PST",
      }).format()
    }, 1000)


  

    function secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " h, ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " min, ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " second" : " sec") : "";
      return hDisplay + mDisplay + sDisplay; 
    }

    this.loadUser();


    firebase.firestore().collection('calls').onSnapshot(
      snapshot => {
            this.callTypes = {};
            snapshot.docs.map(doc => {
              this.callTypes[doc.id] = {title: doc.data().title, lineType: doc.id, callingTimes: doc.data().callingTimes, ppm: doc.data().ppm, waittime: (+(doc.data().waittime.low) + +(doc.data().waittime.high)) / 2, basecost: +doc.data().basecost/ 100, open: doc.data().hours.open, close: doc.data().hours.close, lineWarning: doc.data().lineWarning}
            })
            console.log("CallTypes: ", this.callTypes)
      }
    );

    firebase.firestore().collection('liveCalls').where('dedicated', '==', false).where('onhold', '==', true).onSnapshot(
        snapshot => {
                //This needs to be here static so they refresh from access removed.
                this.GeneralBusiness = [];
                this.GeneralLine = [];
                this.PPBusiness = [];
                this.PPIndividual = [];
                this.PPCollections = [];
                this.BalanceDue = [];
                this.Appointments = [];
                this.TaxPayerProtectionHotline = [];
            snapshot.docs.map(doc => {

              if  (doc.data().status == 'call.answered') {
                if (doc.data()['waittime'] !== undefined) {
                  if (doc.data()['callbacks'] !== undefined) {var callback = true} else {var callback = false}
                  this[doc.data().type].push({
                    price: (((doc.data().timenow.seconds - doc.data().start.seconds) * this.callTypes[doc.data().type].ppm) / 60 + this.callTypes[doc.data().type].basecost), 
                    start_time: doc.data().start.toDate().toLocaleTimeString('en-US'),
                    est_time_left: secondsToHms(Math.max(0, (((doc.data().waittime.low*60) + (doc.data().waittime.high*60)) / 2) - (doc.data().timenow.seconds - doc.data().start.seconds))),
                    progress: Math.max(0, (doc.data().timenow.seconds - doc.data().start.seconds)) / (((doc.data().waittime.low*60) + (doc.data().waittime.high*60)) / 2),
                    id: doc.id,
                  })
                  console.log("LiveCalls: ", this[doc.data().type])
                } else {
                    if (doc.data()['callbacks'] !== undefined) {var callback = true} else {var callback = false}
                    this[doc.data().type].push({
                    price: (((doc.data().timenow.seconds - doc.data().start.seconds) * this.callTypes[doc.data().type].ppm) / 60 + this.callTypes[doc.data().type].basecost), 
                    start_time: doc.data().start.toDate().toLocaleTimeString('en-US'),
                    est_time_left: secondsToHms(1200 - (doc.data().timenow.seconds - doc.data().start.seconds)),
                    progress: Math.max(0, (doc.data().timenow.seconds - doc.data().start.seconds)) / (((15*60) + (25*60)) / 2),
                    id: doc.id,
                  })
                  console.log("LiveCalls: ", this[doc.data().type])
                }
              }
          })
        }
    );  
    
        
  },
  components: { Navbar, Footer },


};
</script>


<style>

.btn {
  border-radius: 0px;
}

.sq-input {
  border: 1px solid rgb(223, 223, 223);
  outline-offset: -2px;
  margin-bottom: 5px;
  display: inline-block;
  border: none;
  color: #32325d;
  line-height: 18px;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  height: 18px;
  -webkit-font-smoothing: antialiased;
}

.sq-input ::placeholder {
  color: #aab7c4;
  opacity: 0.5;
}

/* Define how SqPaymentForm iframes should look when they have focus */

/* Define how SqPaymentForm iframes should look when they contain invalid values */

.sq-input--error {
  outline: 3px auto rgb(255, 97, 97);
}

.errorbox {
  line-height: 14px;
  text-align: left;
}

.error {
  font-size: 10px;
  color: rgb(164, 0, 30);
  width: 45%;
  display: inline-block;

  margin-top: -10px;
  font-weight: 400;
}

/* Customize the "Pay with Credit Card" button */

.button-credit-card {
  min-width: 200px;
  min-height: 20px;
  padding: 0;
  margin: 5px;
  line-height: 20px;
  box-shadow: 2px 2px 1px rgb(200, 200, 200);
  background: rgb(255, 255, 255);
  border-radius: 5px;
  border: 1px solid rgb(200, 200, 200);
  font-weight: bold;
  cursor: pointer;
}

.card-number {
  width: 100%;
}

.modal .payButton {
  margin-left: 0px;
  position: absolute;
  bottom: 0px;
  width: 400px;
}

/* Customize the "{{Wallet}} not enabled" message */

.wallet-not-enabled {
  min-width: 200px;
  min-height: 40px;
  max-height: 64px;
  padding: 0;
  margin: 10px;
  line-height: 40px;
  background: #eee;
  border-radius: 5px;
  font-weight: lighter;
  font-style: italic;
  font-family: inherit;
  display: block;
}

/* Customize the Apple Pay on the Web button */

.button-apple-pay {
  min-width: 200px;
  min-height: 40px;
  max-height: 64px;
  padding: 0;
  margin: 10px;
  background-image: -webkit-named-image(apple-pay-logo-white);
  background-color: black;
  background-size: 100% 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  cursor: pointer;
  display: none;
}

/* Customize the Masterpass button */

.button-masterpass {
  min-width: 200px;
  min-height: 40px;
  max-height: 40px;
  padding: 0;
  margin: 10px;
  background-image: url(https://static.masterpass.com/dyn/img/btn/global/mp_chk_btn_147x034px.svg);
  background-color: black;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 5px;
  border-color: rgb(255, 255, 255);
  cursor: pointer;
}

#sq-walletbox {
  text-align: center;
  vertical-align: top;
  font-weight: bold;
}

#sq-ccbox {
  margin: 5px;
  padding: 0px 10px;
  text-align: center;
  vertical-align: top;
  font-weight: bold;
}

.expiration-date,
.cvv,
.postal-code {
  width: 30%;
  display: inline-block;
}

#card-tainer {
  text-align: left;
  margin-top: 8px;
  background-color: white;
  height: 80px;
  padding: 10px 12px;
  border-radius: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  box-sizing: border-box;
}
</style>  
